<template>
  <div>
    <el-page-header @back="dynamic" content="简介公告" style="margin-bottom: 15px;">
    </el-page-header>
    <template>
      <el-tabs v-model="activeName" @tab-click="handleClick" style="padding: 10px">
        <el-tab-pane label="标签管理" name="first">
          <el-button type="text">
            <el-button size="mini" type="primary" @click="outerVisible = true; getDictionaryByModular(1)">新增</el-button>
          </el-button>
          <el-dialog title="添加" :visible.sync="outerVisible">
            <el-form ref="form" :model="tagForm" label-width="80px" size="mini" label-position="top" class="pt-0">
              <el-form-item>
                <el-input v-model="tagForm.tagName" placeholder="请输入标签名称" style="width: 30%"></el-input>
                <el-input v-model="tagForm.tagType" placeholder="请输入标签样式" style="width: 30%;margin-left: 20px"></el-input>
                <el-button @click="outerVisible = false;" size="mini" style="margin-left: 50px">取 消</el-button>
                <el-button type="primary" size="mini" @click="editNotice();">提交</el-button>
              </el-form-item>
              <el-form-item>

              </el-form-item>
              <el-form-item>

              </el-form-item>
            </el-form>
          </el-dialog>
          <el-table
              :data="tagData"
              style="width: 100%; margin: auto"
              :cell-style="{padding:0}">
            <el-table-column
                fixed
                prop="id"
                label="序号"
                width="50">
              <template slot-scope="scope">
                {{ scope.$index + 1}}
              </template>
            </el-table-column>
            <el-table-column
                prop="tagName"
                label="标签">
              <template slot-scope="scopeNews">
                <el-link @click="onToNewsReader(scopeNews.row.noticeId)">{{scopeNews.row.tagName}}</el-link>
              </template>
            </el-table-column>
            <el-table-column
                prop="tagType"
                label="样式"
                width="150">
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="50">
              <template slot-scope="">
                <el-button
                    @click="outerVisible=true;"
                    style="padding: 2px; margin: 5px">
                  <i class="el-icon-edit"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="轮播大图" name="second">
          <el-row style="margin-top: 50px">
            <el-col :span="7" v-for="(item,index) in imgData1" :key="index" style="margin-right: 4.1%">
              <el-card :body-style="{ padding: '0px' }" shadow="hover">
                <img :src=item.imageUrl class="image" style="width: 100%; display: block">
                <el-row style="padding: 14px;">
                  <el-col :span="16">{{ item.image }}</el-col>
                  <el-col :span="8">
                    <el-button type="success" class="button" size="mini" @click.native.prevent="uploadImg(item.imageId); ImageUpload = true">点击更换</el-button>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="作品管理" name="third">
          <el-button type="text">
            <el-button size="mini" type="primary" @click="AddImg = true;">新增</el-button>
          </el-button>
          <div>
            <span style="color: red">注意：修改作品信息和上传图片等同于替换原有作品，原有作品将会同时删除”首页滚动“和“艺术家作品”内容；</span>
          </div>
          <el-table
              :data="imgData2"
              style="width: 100%; margin: auto"
              :cell-style="{padding:0}">
            <el-table-column
                fixed
                prop="id"
                label="序号"
                width="50">
              <template slot-scope="scope">
                {{ scope.$index + 1}}
              </template>
            </el-table-column>
            <el-table-column
                prop="image"
                label="名称"
                width="150">
            </el-table-column>
            <el-table-column
                prop="imageUrl"
                label="链接">
              <template slot-scope="imageUri">
                <el-link :href="imageUri.row.imageUrl" target="_blank">{{imageUri.row.image}}（点击即可预览）</el-link>
              </template>
            </el-table-column>
            <el-table-column
                prop="isOpen"
                label="显示"
                width="48">
              <template slot-scope="scopeOpen">
                <el-tag v-if="(scopeOpen.row.isOpen === 0)"  type="danger" size="mini">私有</el-tag>
                <el-tag v-if="(scopeOpen.row.isOpen === 1)" size="mini">公开</el-tag>
              </template>
            </el-table-column>
            <el-table-column
                prop="isIndex"
                label="显示"
                width="50">
              <template slot-scope="scopeIndex">
                <el-tag v-if="(scopeIndex.row.isIndex === 0)"  type="danger" size="mini">模块</el-tag>
                <el-tag v-if="(scopeIndex.row.isIndex === 1)" size="mini">首页</el-tag>
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="140">
              <template slot-scope="scopeAllImgId">
                <el-tooltip class="item" effect="dark" content="设为滚动图片" placement="top-start">
                  <el-button
                      @click.native.prevent="getImgData(3,1);setNewImgId(scopeAllImgId.row.imageId);IndexImageEdit = true"
                      style="padding: 2px; margin: 5px 0px">
                    <i class="el-icon-s-home"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="修改信息" placement="top-start">
                  <el-button
                      @click.native.prevent="queryImgById(scopeAllImgId.row.imageId); ImageEdit = true"
                      style="padding: 2px; margin: 5px">
                    <i class="el-icon-edit"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="上传照片" placement="top-start">
                  <el-button
                      @click.native.prevent="uploadImg(scopeAllImgId.row.imageId); ImageUpload = true"
                      style="padding: 2px; margin: 5px 0px">
                    <i class="el-icon-upload"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
                  <el-button
                      @click.native.prevent="delImgById(scopeAllImgId.row.imageId);"
                      style="padding: 2px; margin: 5px 5px">
                    <i class="el-icon-delete-solid"></i>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="滚动管理" name="fourth">
          <div>
            <span style="color: red">注意：修改作品信息和上传图片等同于替换原有作品，原有作品将会同时删除”首页滚动“和“艺术家作品”内容；</span>
          </div>
          <el-table
              :data="imgData3"
              style="width: 100%; margin: auto"
              :cell-style="{padding:0}">
            <el-table-column
                fixed
                prop="id"
                label="序号"
                width="50">
              <template slot-scope="scope">
                {{ scope.$index + 1}}
              </template>
            </el-table-column>
            <el-table-column
                prop="id"
                label="名称"
                width="150">
              <template slot-scope="scope">
                滚动第{{ scope.$index + 1}}幅
              </template>
            </el-table-column>
            <el-table-column
                prop="imageUrl"
                label="名称">
              <template slot-scope="imageUri">
                <el-link :href="imageUri.row.imageUrl" target="_blank">{{imageUri.row.image}} （点击即可阅览）</el-link>
              </template>
            </el-table-column>
            <el-table-column
                prop="isOpen"
                label="显示"
                width="48">
              <template slot-scope="scopeOpen">
                <el-tag v-if="(scopeOpen.row.isOpen === 0)"  type="danger" size="mini">私有</el-tag>
                <el-tag v-if="(scopeOpen.row.isOpen === 1)" size="mini">公开</el-tag>
              </template>
            </el-table-column>
            <el-table-column
                prop="isIndex"
                label="显示"
                width="50">
              <template slot-scope="scopeIndex">
                <el-tag v-if="(scopeIndex.row.isIndex === 0)"  type="danger" size="mini">模块</el-tag>
                <el-tag v-if="(scopeIndex.row.isIndex === 1)" size="mini">首页</el-tag>
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="80">
              <template slot-scope="scopeImgId">
                <el-button
                    @click.native.prevent="queryImgById(scopeImgId.row.imageId); ImageEdit = true"
                    style="padding: 2px; margin: 5px">
                  <i class="el-icon-edit"></i>
                </el-button>
                <el-tooltip class="item" effect="dark" content="上传照片" placement="top-start">
                  <el-button
                      @click.native.prevent="uploadImg(scopeImgId.row.imageId); ImageUpload = true"
                      style="padding: 2px; margin: 5px 0px">
                    <i class="el-icon-upload"></i>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>

        </el-tab-pane>
      </el-tabs>
      <el-dialog title="添加" :visible.sync="ImageUpload">
        <el-form ref="form" :model="tagForm" label-width="80px" size="mini" label-position="top" class="pt-0">
          <el-form-item>
            <el-upload
                class="upload-demo"
                action="/api/imageManager/editImg"
                :on-preview="handlePreview"
                :file-list="fileList"
                :data="image1"
                list-type="picture">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <div  style="margin-top: 50px; text-align: right; padding-right: 30px">
              <el-button @click="ImageUpload = false; clearImage()" size="mini">确  定</el-button>
            </div>

          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="编辑" :visible.sync="ImageEdit">
        <el-form ref="form" :model="imgForm" label-width="80px" size="mini" label-position="top" class="pt-0">
          <el-form-item>
            <el-input v-model="imgForm.image" style="width: 30%"></el-input>
            <el-radio-group v-model="imgForm.isOpen" style="margin-left: 20px; margin-right: 20px" >
              <el-radio-button :label="0">私有</el-radio-button>
              <el-radio-button :label="1">公开</el-radio-button>
            </el-radio-group>
            <el-input v-model="imgForm.sort" disabled style="width: 10%"></el-input>
            <el-button @click="ImageEdit = false;" size="mini" style="margin-left: 50px">取 消</el-button>
            <el-button type="primary" size="mini" @click="editImgById();">提交</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="新增艺术家作品" :visible.sync="AddImg">
        <el-form ref="form" :model="addImgForm" label-width="80px" size="mini" label-position="top" class="pt-0">
          <el-form-item>
            <el-upload
                class="upload-demo"
                action="/api/people/upLoadPeoImg"
                :on-preview="handlePreview"
                :on-success="upImgSucess"
                multiple
                style="width: 50%">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-input v-model="addImgForm.image" style="width: 30%;margin-top: 80px"></el-input>
            <el-radio-group v-model="addImgForm.isOpen" style="margin-left: 20px; margin-right: 20px" >
              <el-radio-button :label="0">私有</el-radio-button>
              <el-radio-button :label="1">公开</el-radio-button>
            </el-radio-group>
            <el-button @click="AddImg = false;" size="mini" style="margin-left: 50px">取 消</el-button>
            <el-button type="primary" size="mini" @click="addImg(3);">提交</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="更换滚动图片" :visible.sync="IndexImageEdit">
        <div>
          <span style="color: red">注意：本页面只是替换首页滚动图片，不会删除图片，”艺术家作品“栏目仍可查看；</span>
          <br />
          <p />
        </div>
        <el-form ref="form" :model="imgForm" label-width="80px" size="mini" label-position="top" class="pt-0">
          <el-form-item>
            <el-select v-model="oldImgId" placeholder="请选择替换的位置">
              <el-option
                  v-for="item in imgData3"
                  :key="item.value"
                  :label="item.image"
                  :value="item.imageId"
                  >
                <span style="float: left">{{ item.image }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">第{{ item.sort }}幅</span>
              </el-option>
            </el-select>
            <el-button @click="IndexImageEdit = false;" size="mini" style="margin-left: 50px">取 消</el-button>
            <el-button type="primary" size="mini" @click="editImgByIndex();">提交</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </template>
  </div>
</template>
<script>
export default {
  name: "tag",
  components: {
  },
  data() {
    return {
      outerVisible: false,
      ImageUpload: false,
      ImageEdit: false,
      IndexImageEdit: false,
      AddImg: false,
      fileList: [],
      image1: {
        imageId: ''
      },
      tagData: [],
      tagForm: {
        tagName: '',
        tagType: ''
      },
      imgForm: {
        imageId: '',
        image: '',
        isIndex: '',
        isOpen: '',
        sort: '',
        imgType: ''
      },
      addImgForm: {
        image: '',
        isOpen: '1',
        imageUrl: '',
        imgType: ''
      },
      activeName: 'first',
      imgData1: [],
      imgData2: [],
      imgData3: [],
      oldImgId: '',
      newImgId: ''
    }
  },
  mounted() {
    this.getTagList();
  },
  methods: {
    dynamic() {
      this.$router.push("dynamic")
    },
    handleClick(tab) {
      switch (tab.name) {
        case "first":
          this.getTagList();
          break;
        case "second":
          this.getImgData(2,1)
          break;
        case "third":
          this.getImgData(3,0)
          break;
        case "fourth":
          this.getImgData(3,1)
          break;
      }
    },
    getTagList() {
      this.$http({
        method: 'get',
        url: '/api/tag/getTagList'
      }).then((res) =>{
        this.tagData = res.data.data
      })
    },
    editNotice() {
      this.$confirm('是否立即提交?' , '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        var noticeUrl = '/api/tag/insertNotice';
        var noticeMethod = 'post';
        if (this.noticeForm.noticeId != null || this.noticeForm.noticeId != '') {
          noticeUrl = '/api/tag/editNotice';
          noticeMethod = 'put'
        }
        this.$http({
          method: noticeMethod,
          url: noticeUrl,
          data: this.noticeForm
        }).then((res) =>{
          this.$message({
            type: 'success',
            message: res.data.msg
          });
          this.noticeForm.noticeTitle = ''
          this.noticeForm.notice = ''
          this.outerVisible = false
          this.getNoticeList()
        }).catch(()=>{
          this.$message({
            type: 'error',
            message: "系统异常"
          })
        })
      }).catch(() => {
      });
    },
    getImgData(type, isIndex) {
      this.$http({
        method: 'get',
        url: '/api/imageManager/getImgByType/' + type + '/' + isIndex
      }).then((res) =>{
        switch (type) {
          case 2:
            this.imgData1 = res.data.data;
            break;
          case 3:
            if (isIndex === 0) this.imgData2 = res.data.data;
            if (isIndex === 1) this.imgData3 = res.data.data;
            break;
        }
      })
    },
    uploadImg(imageId) {
      this.image1.imageId = imageId
    },
    handlePreview(file) {
      console.log(file);
    },
    clearImage() {
      this.fileList = [];
      this.image1.imageId = '';
    },
    queryImgById(imgId) {
      this.$http({
        method: 'get',
        url: '/api/imageManager/getImgById/' + imgId
      }).then((res) =>{
        this.imgForm = res.data.data
      })
    },
    editImgById() {
      this.$confirm('是否立即提交?' , '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        this.$http({
          method: 'put',
          url: '/api/imageManager/editImgName',
          data: this.imgForm
        }).then((res) =>{
          this.$message({
            type: 'success',
            message: res.data.msg
          });
        })
          this.imgForm.image = '';
          this.imgForm.isIndex = '';
          this.imgForm.isOpen = '';
          this.imgForm.sort = '';
          this.imgForm.imgType = '';
          this.ImageEdit = false;
        }).catch(()=>{
          this.$message({
            type: 'error',
            message: "系统异常"
          })
        })
    },//修改图片信息
    delImgById(imgId) {
      this.$confirm('是否删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() =>{
        this.$http({
          method: 'delete',
          url: '/api/imageManager/delImgById/' + imgId
        }).then((res) =>{
          if (res.data.status) {
            this.$message({
              type: 'success',
              message: res.data.msg
            })
            this.getImgData(3,0)
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
            this.getImgData(3,0)
          }
        }).catch(() =>{
          this.$message({
            type: 'error',
            message: '删除失败!'
          })
        })
      }).catch(() =>{
        this.$message({
          type: 'info',
          message: '取消操作'
        })
      })
    },
    editImgByIndex() {
      console.log(this.newImgId + ";" + this.oldImgId)
    },//设为首页滚动图片
    setNewImgId(newImgId) {
      this.newImgId = newImgId
    },
    addImg(imgType) {
      this.addImgForm.imgType = imgType;
      this.$http({
        method: 'post',
        url: '/api/imageManager/addImg',
        data: this.addImgForm
      }).then((res) =>{
        this.$message({
          type: 'success',
          message: res.data.msg
        });
        this.AddImg = false;
        this.getImgData(imgType,0)
      })
    },
    upImgSucess(file){
      this.addImgForm.imageUrl = file
    }
  }
}
</script>

<style scoped>

</style>